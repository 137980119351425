import 'react-multi-carousel/lib/styles.css';
import { Row } from 'react-bootstrap'


export const About = () => {

  return (
    <section className="about" id="about">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="about-bx wow zoomIn">
                        <Row>
                        <h2>Sobre mim</h2>
                        <p>Tenho 21 anos e atualmente estou no 4° período do curso de Design na PUC-GO, cursei também Ciências da Computação<br/>
                        na mesma instituição por 2 semestres, venho incorporando tudo o que aprendi nesses últimos 2 anos nos meus projetos.</p>
                        <p>Desde sempre gostei da área de criação, seja ilustrações ou projetos gráficos de tipos variados, e por ter sido exposto <br>
                        </br>à internet muito novo, pude desenvolver minhas habilidades e adquirir novos conhecimentos. Minhas áreas de atuação  <br/>
                        vão de desenho tradicional à edição de vídeos simples. Também atuo um pouco na área de programação, um bom exemplo<br/>
                        é este site, ele foi totalmente desenvolvido por mim, do zero, incluíndo boa parte dos assets.</p>
                        <p>Sou uma pessoa extremamente quieta, consigo trabalhar muito bem em equipe e tenho facilidade em aprender coisas<br/>
                        novas, estou sempre buscando aprimorar os conhecimentos que já possuo e evito ficar ocioso.</p>
                        <div>
                            <img src={''} alt=''/>
                        </div>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}