import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { NavBar } from './components/navbar';
import { Banner } from './components/banner';
import { Footer } from './components/footer';
import { About } from './components/about'

function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />    
      <About />
      <Footer />
    </div>
  );
}

export default App;
