import { Col, Container, Row } from "react-bootstrap";

export const Banner = () => {

    return (
        <section className="banner" id="home">
            <Container>
                <Row className="text-end">
                    <Col>

                    </Col>
                    <Col xs={12} md={6} xl={7}>  
                        <h1>Oi, eu sou o Ed!</h1>
                        <h3>Ilustrador e Estudante de Design</h3>
                        <h6>Experiência com ilustração desde 2007<br/>e design gráfico desde 2013.</h6>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}